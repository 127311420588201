export enum UserType{
    old = '老',
    soldier = '军',
    common = '普'
}

// 用户
export interface User{
    type: UserType,
    id: number,
    name: string
}

export interface TimeArea{
    id: number
    startTime: Date
    endTime: Date
    maxNumber: number
    // 以占用号数量
    occupy: number
    // 起始号码
    startNum: number
}

// 待签到用户
export interface ListUser{
    user: User,
    // 所选时间段id
    area: number,
    // 号码
    num: number,
    // 实际签到的时间范围
    signArea?: number,
}