import {TimeArea} from "@/types/index";

type generateParams = {
    startTime: Date,
    endTime: Date,
    // 步进，按分钟拆分
    step: number,
    // 每个时段可以挂号的数量
    maxNumber: number[],
    // 如果没有指定maxNumber，使用默认值 
    defaultMaxNumber: number,
}
// 生成时段号
export const generateTimeAreas = (params: generateParams)=>{
    // 取出参数
    const {startTime, endTime, step, maxNumber, defaultMaxNumber} = params;
    // 存放时间逻辑
    const timeAreas: TimeArea[] = [];
    // 获取当前时间
    let currentTime = new Date(startTime);
    // 索引用作id
    let id = 1;
    // 当前时间段的起始号码
    let startNum = 0;
    // 当时间小于结束时间时
    while(currentTime < endTime){
        const nextTime = new Date(currentTime);
        nextTime.setMinutes(currentTime.getMinutes() + step);
        timeAreas.push({
            id: id++,
            startTime: currentTime,
            endTime: nextTime,
            maxNumber: maxNumber[timeAreas.length] || defaultMaxNumber,
            occupy: 0,
            startNum: startNum
        });
        startNum += maxNumber[timeAreas.length-1] || defaultMaxNumber
        currentTime = nextTime;
    }
    return timeAreas;
}