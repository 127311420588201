import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "num" }
const _hoisted_3 = { class: "box" }
const _hoisted_4 = { class: "line-up" }
const _hoisted_5 = { style: {"color":"green"} }
const _hoisted_6 = { class: "line-up" }
const _hoisted_7 = { style: {"color":"green"} }

import {generateTimeAreas} from '@/hooks/generateTimeAreas';
import { useGetNumber } from '@/hooks/useGetNumber';
import { ListUser, User, UserType } from '@/types';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { TimeArea } from '@/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const store = useStore()
// 获取今日的开始和结束时间
const startTime = new Date().setHours(8,0,0,0);
const endTime = new Date().setHours(18,0,0,0);
store.commit('setAreas', generateTimeAreas({
  startTime: new Date(startTime),
  endTime: new Date(endTime),
  step: 60,
  maxNumber: [0,10,7,5],
  defaultMaxNumber: 5
}))
const areas = computed(()=>store.state.areas as TimeArea[]);
// 默认选中还没有被约满的时间段
const canSelectId = areas.value.filter(area => area.occupy < area.maxNumber).map(area => area.id)[0];
if(!canSelectId){
  ElMessage.warning('今日已经约满，请明天再来');
}
var area = ref<number>(canSelectId)
// 迟到的用户
const isLate = (user: ListUser):boolean=>{
  let flag = false;
  // 获取对应的时间段
  const signTime = getAreaInfo({area: user.signArea}) as TimeArea
  const _area = getAreaInfo({area: user.area})
  flag = _area!.endTime.getTime() < signTime.startTime.getTime()
  return flag
}
// 设置当前时间
const currentTime = ref<Date>(new Date());
const {generateUser, getNumber, userSignIn, getAreaInfo} = useGetNumber();
const user = ref<User>(generateUser());
// 取号
const generateNumber = ()=>{
  // 将用户进行取号, 取号成功后用户将会进入到待报道列表 
  try{
    getNumber({
      user: user.value,
      selectId: area.value,
      residueNum: selectNum.value
    });
    // 重新生成用户
    user.value = generateUser()
    selectNum.value = undefined
  }catch(e: any){
    ElMessage.error(e.toString())
  }
}
// 用户签到
const signIn = (item: ListUser)=>{
  userSignIn(currentTime.value, item)
}
const getAreaById = (id: number)=>{
  return areas.value.filter(item=>item.id === id)[0];
}
const linup = computed(()=>store.state.lineUp as ListUser[]);
const waitList = computed(()=>store.state.waitList as ListUser[]);

// 预留号
const reserve = computed(()=>store.state.reserve as number[]);
const reverseText = ref("");
const setReverse = ()=>{
  if(!reverseText.value)return ElMessage.error('请输入预留号码')
  const num = parseInt(reverseText.value)
  if(reserve.value.includes(num)){
    ElMessage.error('不可重复添加')
    return 
  }
  // 不可预留已被挂取的号
  const arr: number[] = [...linup.value, ...waitList.value].map(item=>item.num)
  if(arr.includes(num)){
    ElMessage.error('预留号已被使用')
    return
  }
  const tmp: number[] = reserve.value.concat(num)
  store.commit('setReserve', tmp)
  reverseText.value = ''
  ElMessage.success('预留成功')
}
const residueNum = (area: TimeArea)=>{
  let num = 0
  reserve.value.forEach(element => {
    const _a = getAreaInfo({num: element})
    if(_a?.id === area.id){
      num += 1
    }
  });
  return area.maxNumber - area.occupy - num
}
const selectNum = ref<number>()

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_column = _resolveComponent("el-column")!
  const _component_el_time_picker = _resolveComponent("el-time-picker")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, "当前虚拟用户为：" + _toDisplayString(user.value), 1),
    _createVNode(_component_el_space, { style: {"margin-bottom":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          modelValue: selectNum.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectNum).value = $event)),
          placeholder: "Select",
          size: "large",
          clearable: "",
          style: {"width":"240px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reserve.value, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item,
                label: item,
                value: item
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: generateNumber,
          size: "large"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("取号")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_space, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reserve.value, (item) => {
          return (_openBlock(), _createBlock(_component_el_tag, { key: item }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_el_space, { style: {"margin":"10px 0"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_column, { span: 4 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              placeholder: "请设置预留号",
              type: "number",
              modelValue: reverseText.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((reverseText).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { onClick: setReverse }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("添加")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_space, null, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("span", null, "当前时间：", -1)),
        _createVNode(_component_el_time_picker, {
          modelValue: currentTime.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentTime).value = $event)),
          placeholder: "Arbitrary time"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_radio_group, {
      modelValue: _unref(area),
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(area) ? (area).value = $event : area = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areas.value, (item) => {
          return (_openBlock(), _createBlock(_component_el_radio, {
            value: item.id,
            size: "large",
            border: "",
            key: item.id,
            class: "area",
            disabled: item.maxNumber <= item.occupy
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(item.startTime.toLocaleTimeString()) + "-" + _toDisplayString(item.endTime.toLocaleTimeString()), 1),
              _createElementVNode("span", _hoisted_2, "剩余号数：" + _toDisplayString(residueNum(item)), 1)
            ]),
            _: 2
          }, 1032, ["value", "disabled"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "等待报道的用户:")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linup.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [
            _createVNode(_component_el_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_space, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(item.user.name), 1),
                    _createElementVNode("span", null, _toDisplayString(getAreaById(item.area).startTime.toLocaleTimeString()) + " ~ " + _toDisplayString(getAreaById(item.area).endTime.toLocaleTimeString()), 1),
                    _createElementVNode("strong", _hoisted_5, _toDisplayString(item.num), 1),
                    (item.user.type !== _unref(UserType).common)
                      ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.user.type), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: ($event: any) => (signIn(item)),
                  style: {"float":"right"}
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("报道")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("p", null, [
          _createElementVNode("strong", null, "候诊的用户:")
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(waitList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item",
            key: index
          }, [
            _createVNode(_component_el_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_space, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(item.user.name), 1),
                    _createElementVNode("span", null, _toDisplayString(getAreaById(item.area).startTime.toLocaleTimeString()) + " ~ " + _toDisplayString(getAreaById(item.area).endTime.toLocaleTimeString()), 1),
                    _createElementVNode("strong", _hoisted_7, _toDisplayString(item.num), 1),
                    (item.user.type !== _unref(UserType).common)
                      ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.user.type), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (isLate(item))
                      ? (_openBlock(), _createBlock(_component_el_tag, {
                          key: 1,
                          type: "warning"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("迟")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})