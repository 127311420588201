
import { ListUser, TimeArea, User, UserType } from "@/types";
import { ElMessage } from "element-plus";
import { useStore } from 'vuex';
interface SpecialRule{
    // 类型
    type: UserType
    // 排序
    sort: number
}
const specials: SpecialRule[] = [
    // 老人排在第四个
    {type: UserType.old, sort: 4},
    // 军人排在第一个
    {type: UserType.soldier, sort: 1},
]
// 用户取号
export const useGetNumber = ()=>{
    const store = useStore();
    const areas = store.state.areas as TimeArea[]

    const generateUser = ():User=>{
        // 获取随机数
        const random = Math.floor(Math.random() * 100);
        // 6-为军人，8-其余为老人, 其余为普通人
        const type = Math.floor(random / 10) > 7 ? UserType.soldier : Math.floor(random /10) < 2 ? UserType.old : UserType.common;
        return {
            type,
            id: random,
            name: generateRandomName(),
        }
    };
    // 取号
    const getNumber = (options: {user: User, selectId: number, residueNum?: number})=>{
        const reserve = store.state.reserve as number[]
        const {selectId, user} = options;
        // 获取可选时间
        const area = getAreaInfo({area: selectId})
        if(!area){
            throw new Error('当前选择时间段不可挂号');
        }
        // 计算号码
        let num = 1;
        if(!options.residueNum){
            const occupyNum = store.getters.occupyNum;
            while(occupyNum.includes(num)){
                num += 1;
            }
            // 超出最大值
            if(num > area.maxNumber){
                ElMessage.error('当前时段已挂满')
                return
            }
        }else{
            // 挂预留号
            num = options.residueNum
            // 如果是挂预留号，则需要去除被使用的号码
            const index = reserve.findIndex(n=>n === options.residueNum)
            reserve.splice(index, 1)
            store.commit('setReserve', reserve)
        }
        // 生成列表用户
        const listUser: ListUser = {
            user: user,
            area: area.id,
            num,
        }
        // 名额占用
        areas.forEach(item=>{
            if(item.id === selectId){
                item.occupy += 1;
            }
        })
        store.commit('setLineUp', [...store.state.lineUp, listUser])
        store.commit('setAreas', [...areas])
    }

    
    const firstName = ['张', '王', '李', '赵', '刘', '粟', '景', '段']; // 姓氏数组
    const lastName = ['明', '红', '华', '国', '强', '天', '甜']; // 名字数组
    
    const generateRandomName = ():string=>{
        const randomFirstName = firstName[Math.floor(Math.random() * firstName.length)];
        const randomLastName = lastName[Math.floor(Math.random() * lastName.length)];
        return randomFirstName + randomLastName;
    }

    // 用户签到
    const userSignIn = (currentTime: Date, user: ListUser)=>{
        // 获取列表
        const list = store.state.waitList as ListUser[]
        const lineup = store.state.lineUp as ListUser[]
        const arr = user.user.type === UserType.common ? commonUserJoinList(list, user, currentTime) : insertWaitList(list, user, currentTime)
        // 更新
        store.commit('setWaitList', arr)
        // 删除掉待签到的信息
        const index = lineup.findIndex(item=>item.num === user.num)
        lineup.splice(index,1)
        store.commit('setLineUp', lineup)
    }

    // 特殊用户插队
    const insertWaitList = (list: ListUser[], user:ListUser, currentTime: Date)=>{
        const arr = JSON.parse(JSON.stringify(list)) as ListUser[]
        // 通过用户类型拿去排序
        const special = specials.filter(item=>item.type === user.user.type)[0]
        // 用户类型不需要特殊处理,
        if(!special){
            arr.push(user)
            return arr
        }
        //列表长度不够，走正常逻辑
        if(special.sort > arr.length){
            return commonUserJoinList(arr, user, currentTime)
        }
        // 需要在指定位插入
        arr.splice(special.sort-1,0,user)
        return arr
    }
    // 普通用户排队
    const commonUserJoinList = (list: ListUser[], user: ListUser, currentTime: Date)=>{
        const arr = JSON.parse(JSON.stringify(list)) as ListUser[]
        // 生成签到时间
        user.signArea = getAreaInfo({time: currentTime})?.id
        // 通过索引判断用户是否迟到
        const nowIndex = areas.findIndex(item=>{
            return item.startTime <= currentTime && item.endTime >= currentTime
        })
        const userIndex = 
        areas.findIndex(item=>{
            return item.id === user.area
        })
        // 用户迟到，插入到提前报道的用户前
        if(userIndex < nowIndex){
            const arr1 = arr.filter(item=>areas.findIndex((a=>a.id === item.area)) <= nowIndex)
            // 提前签到
            const arr2 = arr.filter(item=>areas.findIndex((a=>a.id === item.area)) > nowIndex)
            return [...arr1, user, ...arr2]
        }
        const waitList = store.state.waitList as ListUser[]
        // 仅会插入迟到一个时段的用户，超过的就不再插入
        const lateIndex = waitList.findIndex(item=>{
            if(item.user.type === UserType.soldier)return false;
            const _index = areas.findIndex(a=>a.id === item.signArea)
            console.log(_index, nowIndex)
            return nowIndex - _index === 1
        })
        lateIndex !== -1 ? arr.splice(lateIndex, 0, user) : arr.push(user)
        return arr
    }
    const getAreaInfo = (data: {area?:number, time?: Date, num?: number}):TimeArea|undefined =>{
        const {area, time, num} = data
        let tmp: TimeArea | undefined = undefined;
        // 通过id获取
        if(area){
            tmp = areas.find(item=>item.id === area);
            return tmp;
        }
        // 通过时间获取
        if(time){
            areas.forEach(item=>{
                if(item.startTime <= time && item.endTime >= time){
                    tmp = item
                }
            })
            return tmp;
        }
        // 通过序号获取
        if(num){
            areas.forEach(item=>{
                if(item.startNum <= num && item.startNum + item.maxNumber >= num){
                    tmp = item
                }
            })
            return tmp;
        }
        return undefined;
    }
    return {
        generateUser,
        getNumber,
        userSignIn,
        getAreaInfo
    }
}
