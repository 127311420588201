<template>
  <div class="home">
    <h4>当前虚拟用户为：{{ user }}</h4>
    <el-space style="margin-bottom: 10px;">
      <el-select
      v-model="selectNum"
      placeholder="Select"
      size="large"
      clearable
      style="width: 240px"
    >
      <el-option
        v-for="item in reserve"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
      <el-button type="primary" @click="generateNumber" size="large">取号</el-button>
    </el-space>
    <el-space>
      <el-tag v-for="item in reserve" :key="item">{{ item }}</el-tag>
    </el-space>
    <el-space style="margin: 10px 0;">
      <el-column :span="4">
        <el-input placeholder="请设置预留号" type="number" v-model="reverseText"/>
      </el-column>
      <el-button @click="setReverse">添加</el-button>
    </el-space>
    <el-space>
      <span>当前时间：</span>
      <el-time-picker v-model="currentTime" placeholder="Arbitrary time"/>
    </el-space>
    <el-radio-group v-model="area">
      <el-radio :value="item.id" size="large" border v-for="item in areas" :key="item.id" class="area" :disabled="item.maxNumber <= item.occupy">
        <span>{{item.startTime.toLocaleTimeString()}}-{{item.endTime.toLocaleTimeString()}}</span>
        <span class="num">剩余号数：{{ residueNum(item) }}</span>
      </el-radio>
    </el-radio-group>
    <div class="box">
      <div class="line-up">
      <p><strong>等待报道的用户:</strong></p>
      <div class="item" v-for="(item, index) in linup" :key="index">
        <el-card>
          <el-space>
            <span>{{ item.user.name }}</span>
            <span>{{ getAreaById(item.area).startTime.toLocaleTimeString()}} ~ {{ getAreaById(item.area).endTime.toLocaleTimeString() }}</span>
            <strong style="color: green;">{{ item.num }}</strong>
            <el-tag v-if="item.user.type !== UserType.common">{{ item.user.type }}</el-tag>
          </el-space>
          <el-button type="primary" @click="signIn(item)" style="float: right;">报道</el-button>
        </el-card>
      </div>
    </div>
    <div class="line-up">
      <p><strong>候诊的用户:</strong></p>
      <div class="item" v-for="(item, index) in waitList" :key="index">
        <el-card>
          <el-space>
            <span>{{ item.user.name }}</span>
            <span>{{ getAreaById(item.area).startTime.toLocaleTimeString()}} ~ {{ getAreaById(item.area).endTime.toLocaleTimeString() }}</span>
            <strong style="color: green;">{{ item.num }}</strong>
            <el-tag v-if="item.user.type !== UserType.common">{{ item.user.type }}</el-tag>
            <el-tag v-if="isLate(item)" type="warning">迟</el-tag>
          </el-space>
        </el-card>
      </div>
    </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {generateTimeAreas} from '@/hooks/generateTimeAreas';
import { useGetNumber } from '@/hooks/useGetNumber';
import { ListUser, User, UserType } from '@/types';
import { ElMessage } from 'element-plus';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { TimeArea } from '@/types';

const store = useStore()
// 获取今日的开始和结束时间
const startTime = new Date().setHours(8,0,0,0);
const endTime = new Date().setHours(18,0,0,0);
store.commit('setAreas', generateTimeAreas({
  startTime: new Date(startTime),
  endTime: new Date(endTime),
  step: 60,
  maxNumber: [0,10,7,5],
  defaultMaxNumber: 5
}))
const areas = computed(()=>store.state.areas as TimeArea[]);
// 默认选中还没有被约满的时间段
const canSelectId = areas.value.filter(area => area.occupy < area.maxNumber).map(area => area.id)[0];
if(!canSelectId){
  ElMessage.warning('今日已经约满，请明天再来');
}
var area = ref<number>(canSelectId)
// 迟到的用户
const isLate = (user: ListUser):boolean=>{
  let flag = false;
  // 获取对应的时间段
  const signTime = getAreaInfo({area: user.signArea}) as TimeArea
  const _area = getAreaInfo({area: user.area})
  flag = _area!.endTime.getTime() < signTime.startTime.getTime()
  return flag
}
// 设置当前时间
const currentTime = ref<Date>(new Date());
const {generateUser, getNumber, userSignIn, getAreaInfo} = useGetNumber();
const user = ref<User>(generateUser());
// 取号
const generateNumber = ()=>{
  // 将用户进行取号, 取号成功后用户将会进入到待报道列表 
  try{
    getNumber({
      user: user.value,
      selectId: area.value,
      residueNum: selectNum.value
    });
    // 重新生成用户
    user.value = generateUser()
    selectNum.value = undefined
  }catch(e: any){
    ElMessage.error(e.toString())
  }
}
// 用户签到
const signIn = (item: ListUser)=>{
  userSignIn(currentTime.value, item)
}
const getAreaById = (id: number)=>{
  return areas.value.filter(item=>item.id === id)[0];
}
const linup = computed(()=>store.state.lineUp as ListUser[]);
const waitList = computed(()=>store.state.waitList as ListUser[]);

// 预留号
const reserve = computed(()=>store.state.reserve as number[]);
const reverseText = ref("");
const setReverse = ()=>{
  if(!reverseText.value)return ElMessage.error('请输入预留号码')
  const num = parseInt(reverseText.value)
  if(reserve.value.includes(num)){
    ElMessage.error('不可重复添加')
    return 
  }
  // 不可预留已被挂取的号
  const arr: number[] = [...linup.value, ...waitList.value].map(item=>item.num)
  if(arr.includes(num)){
    ElMessage.error('预留号已被使用')
    return
  }
  const tmp: number[] = reserve.value.concat(num)
  store.commit('setReserve', tmp)
  reverseText.value = ''
  ElMessage.success('预留成功')
}
const residueNum = (area: TimeArea)=>{
  let num = 0
  reserve.value.forEach(element => {
    const _a = getAreaInfo({num: element})
    if(_a?.id === area.id){
      num += 1
    }
  });
  return area.maxNumber - area.occupy - num
}
const selectNum = ref<number>()
</script>

<style scoped lang="scss">
.home{
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  .area{
    margin-top: 10px;
  }
  .box{
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
    .line-up{
      flex: 1;
      border: 1px solid #EEE;
      text-align: left;
      padding: 10px;
      .item{
        margin-bottom: 10px;
      }
    }
  }
}
</style>