import { ListUser, TimeArea } from '@/types/index'
import { createStore } from 'vuex'

interface State{
  areas: TimeArea[],
  selectArea?: number
  currentTime?: Date,
  lineUp: ListUser[],
  waitList: ListUser[],
  reserve: number[],
}
export default createStore({
  state: <State>{
    areas: [],
    currentTime: undefined,
    selectAreaId: undefined,
    // 待签到列表
    lineUp:[],
    // 候诊列表
    waitList: [],
    reserve: [],
  },
  mutations: {
    setAreas(state: State, arr: TimeArea[]){
      state.areas = arr;
    },
    setSelectArea(state: State, area: number){
      state.selectArea = area;
    },
    setCurrentTime(state: State, time: Date){
      state.currentTime = time;
    },
    setLineUp(state: State, users: ListUser[]){
      state.lineUp = users;
    },
    setWaitList(state: State, users: ListUser[]){
      state.waitList = users;
    },
    setReserve(state: State, reserve: number[]){
      state.reserve = reserve;
    }
  },
  actions: {

  },
  getters: {
    occupyNum: (state)=>{
      // 获取所有被占用的数字
      let tmpArr = (state.lineUp as ListUser[]).concat(state.waitList as ListUser[]).map(item=>item.num)
      tmpArr = tmpArr.concat(state.reserve)
      return tmpArr
    }
  }
})
